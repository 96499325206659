import { useInfiniteQuery, InfiniteQueryResult, queryCache } from 'react-query';
import { Account } from 'types/accounts';
import { getAccountsList } from '../utils/urls';
import { getTokenWithType } from './useAuth';
const pageLength = 100;

function getColumnFromId(id: string) {
  const idsList = [
    'Phone Display',
    'CID',
    'SID',
    'PID',
    'Answer Script',
    'Account Name',
    'Rel Manager',
    'City',
    'State',
    'Time',
    'Status',
    'Service',
    'Call Recording',
    'Routing Group (Workflow)',
    'Branch-out Group',
    'Press Any Key to Answer',
    'Twilio Flex Priority',
  ];
  const index = idsList.findIndex(item => item === id);
  return `${index + 1}`;
}

async function fetchAccounts({ requestData }: TVariables): Promise<TResult> {
  const response = await fetch(getAccountsList, {
    method: 'POST',
    headers: {
      Authorization: getTokenWithType(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  });
  return response.json();
}

type resultType = {
  data: Array<Account>;
  draw: number;
  isAutoRefreshEnabled: boolean;
  error: Error;
  recordsFiltered: number;
  recordsTotal: number;
};

export type TResult = {
  message: string;
  isSuccess: boolean;
  data: Array<resultType>;
  recordsFiltered: number;
  recordsTotal: number;
  draw: number;
  error: Error | null;
};

export type TError = { message: string };
export type TVariables = {
  requestData: any;
};

function formatRequestData(
  page: number,
  accountStatus: string,
  keywordSearch: string,
  columnsSearch: Record<string, string>,
  sortedColumn: string,
  sortOrder: string,
): any {
  const value = {
    start: page * 100,
    draw: 1,
    length: 100,
    columns: [
      {
        data: 'AccountStatusFilter',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: accountStatus.toLowerCase(),
        },
      },

      {
        data: 'VDN,ShortAnswerScript',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: columnsSearch['Phone Display']
            ? columnsSearch['Phone Display']
            : '',
        },
      },

      {
        data: 'CID',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: columnsSearch.CID ? columnsSearch.CID : '',
        },
      },
      {
        data: 'SID',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: columnsSearch.SID ? columnsSearch.SID : '',
        },
      },
      {
        data: 'PID',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: columnsSearch.PID ? columnsSearch.PID : '',
        },
      },
      {
        data: 'AnswerScript',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: columnsSearch['Answer Script']
            ? columnsSearch['Answer Script']
            : '',
        },
      },
      {
        data: 'Name',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: columnsSearch['Account Name']
            ? columnsSearch['Account Name']
            : '',
        },
      },
      {
        data: 'RelationshipManager',
        name: 'RelationshipManager.FirstName,RelationshipManager.LastName',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: columnsSearch['Rel Manager']
            ? columnsSearch['Rel Manager']
            : '',
        },
      },
      {
        data: 'City',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: columnsSearch.City ? columnsSearch.City : '',
        },
      },
      {
        data: 'StateProvince',
        name: 'StateProvince.Abbreviation',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: columnsSearch.State ? columnsSearch.State : '',
        },
      },
      {
        data: 'TimeZone',
        name: 'TimeZone.Abbreviation',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value: columnsSearch.Time ? columnsSearch.Time : '',
        },
      },
      {
        data: 'Status',
        name: 'Status.Description',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value:
            columnsSearch && columnsSearch.Status ? columnsSearch.Status : '',
        },
      },
      {
        data: 'AccountService',
        name: 'AccountService.Description',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value:
            columnsSearch && columnsSearch.Service ? columnsSearch.Service : '',
        },
      },
      {
        data: 'EnableRecording',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value:
            columnsSearch &&
            columnsSearch['Call Recording'] &&
            columnsSearch['Call Recording']?.toLocaleLowerCase() === 'yes'
              ? 'true'
              : columnsSearch['Call Recording']?.toLocaleLowerCase() === 'no'
              ? 'false'
              : '',
        },
      },
      {
        data: 'TwilioAccountWorkFlow',
        name: 'TwilioAccountWorkFlow.TwilioWorkFlow.Name',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value:
            columnsSearch && columnsSearch['Routing Group (Workflow)']
              ? columnsSearch['Routing Group (Workflow)']
              : '',
        },
      },
      {
        data: 'TwilioAccountBranchOutCondition',
        name: 'TwilioAccountBranchOutCondition.TwilioBranchOutCondition.Name',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value:
            columnsSearch && columnsSearch['Branch-out Group']
              ? columnsSearch['Branch-out Group']
              : '',
        },
      },
      {
        data: 'TwilioPAK',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value:
            columnsSearch &&
            columnsSearch['Press Any Key to Answer'] &&
            columnsSearch['Press Any Key to Answer']?.toLocaleLowerCase() ===
              'yes'
              ? 'true'
              : columnsSearch[
                  'Press Any Key to Answer'
                ]?.toLocaleLowerCase() === 'no'
              ? 'false'
              : '',
        },
      },
      {
        data: 'TwilioFlexPriority',
        name: '',
        searchable: true,
        orderable: true,
        search: {
          regex: false,
          value:
            columnsSearch && columnsSearch['Twilio Flex Priority']
              ? columnsSearch['Twilio Flex Priority']
              : '',
        },
      },
    ],
    order: [
      {
        column: sortedColumn,
        dir: sortOrder,
      },
    ],
    search: {
      regex: false,
      value: `${keywordSearch}`,
    },
  };
  return value;
}

function useAccounts(
  page: number,
  accountStatus: string,
  keywordSearch: string,
  columnsSearch: Record<string, string>,
  sortItem: { sortKey: string; sortOrder: string },
): InfiniteQueryResult<TResult, TError> {
  const data: Array<TResult> | undefined = queryCache.getQueryData([
    'accounts_list',
    accountStatus,
    keywordSearch,
    columnsSearch,
    sortItem,
  ]);

  let start = data ? data.length : 0;
  if (start > 0 && data) {
    const totalPages = data[0].recordsFiltered / pageLength;
    if (totalPages > 1) {
      start = start > totalPages ? totalPages : start;
    } else {
      start = 0;
    }
  }

  const requestData = formatRequestData(
    start,
    accountStatus,
    keywordSearch,
    columnsSearch,
    getColumnFromId(sortItem.sortKey),
    sortItem.sortOrder,
  );

  return useInfiniteQuery(
    ['accounts_list', accountStatus, keywordSearch, columnsSearch, sortItem],
    () => fetchAccounts({ requestData }),
    {
      getFetchMore: (lastGroup, allGroups) => {
        return allGroups.length < lastGroup.recordsFiltered / pageLength
          ? true
          : null;
      },
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  );
}

export { useAccounts };
