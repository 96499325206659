import React, {
  ReactElement,
  SyntheticEvent,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import { queryCache } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { useForm, Controller, useFieldArray } from 'react-hook-form';

import {
  Grid,
  Stack,
  Checkbox,
  Button,
  FormLabel,
  ReactSelectWithFlags,
  InputMask,
} from 'ui/components';
import { showErrorToast, showSuccessToast } from 'utils/showToast';
import {
  FormInput,
  FormSelect,
  FormGroup,
  Card,
  Loader,
  FormReactSelect,
} from 'ui/patterns';
import { Box, Flex } from 'rebass/styled-components';

import {
  useGetBranchOutGroup,
  useGetRoutingSubGroup,
  useGetTwilioDetails,
  useGetCountryList,
  useGetPhoneTypes,
  useSaveTwilioInfo,
} from 'hooks';
import {
  optionList,
  getCountryObj,
  formatData,
  isCountryCodeInUSFormat,
  countryOptions,
  isPhnIsInvalidOrNot,
  hasUsFormat,
} from 'utils/reactCountrySelectHelper';
import usePrompt from 'hooks/usePrompt';
import { CountryType } from 'types/countryType';
import {
  PhoneNumberChangeAlertWrapper,
  PhoneNumberChangeAlert,
} from './styles';
import Icons from 'assets/icons';
import { TwilioPhone, TwilioContactNumbers } from 'types/accounts';

function useHelperHook(id: number) {
  const { data: branchOutData, isLoading: l1 } = useGetBranchOutGroup();
  const { data: routingSubGroup, isLoading: l2 } = useGetRoutingSubGroup();
  const { data: countryList, isLoading: l3 } = useGetCountryList();
  const { data: twilioInfo, isLoading: l4 } = useGetTwilioDetails(id);
  const { data: phoneTypes, isLoading: l5 } = useGetPhoneTypes();
  return {
    branchOutData,
    routingSubGroup,
    countryList,
    twilioInfo,
    phoneTypes,
    isLoading: l1 || l2 || l3 || l4 || l5,
  };
}

function Telephony(): ReactElement {
  const history = useHistory<{ ID: number }>();
  const { accountId } = useParams<{
    accountId: string;
    tabId?: string;
  }>();

  const popover: any = useRef();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    errors,
    setValue,

    formState: { isDirty },
  } = useForm<TwilioPhone>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'TwilioContactNumbers',
  });

  const {
    branchOutData,
    routingSubGroup,
    countryList,
    twilioInfo,
    phoneTypes,
    isLoading,
  } = useHelperHook(parseInt(accountId));

  const [pageIsDirty, setPageIsDirty] = useState<boolean>(false);
  const [twilioData, setTwilioData] = useState<TwilioContactNumbers>([]);

  const [saveAccountInfo, { isLoading: isSaveAccountInfoLoading }] =
    useSaveTwilioInfo();

  const countryCode = useMemo(() => {
    if (countryList) {
      return countryOptions(countryList);
    }
  }, [countryList]);
  let permissions: any = [];
  const permissionSet = localStorage.getItem('userPermissions');
  if (permissionSet) {
    permissions = JSON.parse(permissionSet);
  }

  const Phone: any = watch('TwilioContactNumbers');
  const TwilioBranchOutConditionsId = watch('TwilioBranchOutConditionsId');
  const TwilioWorkFlowId = watch('TwilioWorkFlowId');
  const EnableRecording = watch('EnableRecording');
  const TwilioFlexPriority = watch('TwilioFlexPriority');
  const TwilioPAK = watch('TwilioPAK');

  const [showPopover, setShowPopover] = useState<any>({});

  //Set mask pattern for input-mask based on country code
  function maskPattern(countryId: number | string) {
    const countryCode = countryList?.find(
      (item: CountryType) => item?.Id === countryId,
    )?.ISDCode;
    if (countryCode === '+1') {
      return '999-999-9999';
    } else return '999999999999999';
  }

  useEffect(() => {
    if (!isLoading && twilioInfo) {
      let tempTwilio = { ...twilioInfo };

      if (twilioInfo?.TwilioContactNumbers?.length === 0) {
        tempTwilio = {
          ...tempTwilio,
          TwilioContactNumbers: [
            {
              PhoneNumber: '',
              PhoneTypeId: '',
              CountryId: 1,
              Id: 0,
            },
          ],
        };
      }
      setTwilioData([]);
      reset({ ...tempTwilio });
    }
  }, [reset, isLoading, twilioInfo, setValue]);

  const handleRemove = (index: any) => {
    const tempCount: Array<{
      PhoneNumber: number;
      PhoneTypeId: string;
      CountryId: number;
      Id: number;
    }> = [...Phone];
    remove(index);
    const val = tempCount.splice(index, 1);
    reset({
      TwilioBranchOutConditionsId: TwilioBranchOutConditionsId,
      TwilioWorkFlowId: TwilioWorkFlowId,
      EnableRecording: EnableRecording,
      TwilioContactNumbers: [...tempCount],
      TwilioFlexPriority: TwilioFlexPriority,
      TwilioPAK: TwilioPAK,
    });
    setTwilioData([...twilioData, ...val]);
  };

  const handleAppendEntry = () => {
    const val = [];
    val.push({
      PhoneNumber: '',
      PhoneTypeId: '',
      CountryId: 1,
      IsDeleted: false,
      Id: 0,
    });
    append({
      PhoneNumber: '',
      PhoneTypeId: '',
      CountryId: 1,
      Id: 0,
    });

    const tempPhone: any = watch('TwilioContactNumbers');
    reset({
      TwilioBranchOutConditionsId: TwilioBranchOutConditionsId,
      TwilioWorkFlowId: TwilioWorkFlowId,
      EnableRecording: EnableRecording,
      TwilioContactNumbers: [...tempPhone, ...val],
      TwilioFlexPriority: TwilioFlexPriority,
      TwilioPAK: TwilioPAK,
    });

    setPageIsDirty(true);
  };

  const telephonyDes = useMemo(() => {
    if (branchOutData) {
      const val = branchOutData?.map((item: any) => {
        const returnValue: any = {};
        returnValue.label = (
          <div className="label">
            <span>{item.name}</span>
            <p> {item?.description}</p>
          </div>
        );
        returnValue.value = item?.id;
        return returnValue;
      });
      return val;
    }
  }, [branchOutData]);

  useEffect(() => {
    if (isDirty) setPageIsDirty(isDirty);
  }, [isDirty]);

  usePrompt(
    pageIsDirty,
    'Are you sure you want to leave? Changes that you made may not be saved.',
  );

  const onSubmit = (data: any) => {
    const formattedData = formatData(
      { ...data },
      accountId,
      twilioData,
      countryList,
    );
    saveAccountInfo(
      {
        ...formattedData,
        AccountId: accountId ? accountId : 0,
      },
      {
        onSuccess: (mutationResult: any, data: any) => {
          if (data?.AccountId && mutationResult.AccountId) {
            showSuccessToast({
              message: 'Your changes were successfully saved.',
              autoClose: 3000,
            });
            queryCache.invalidateQueries(['get_twilio_info'], {
              refetchInactive: true,
            });
          }
          setPageIsDirty(false);
        },
        onError: (e: any) => {
          showErrorToast({
            message: `${'"An error occured while attempting to save your modifications.Please try again.'}`,
          });
        },
      },
    );
  };

  function onClickHandler(event: any) {
    const includesPopoverElement = event
      .composedPath()
      .includes(popover?.current);
    if (popover?.current && !includesPopoverElement) {
      const newObject: any = {};
      Object.entries(showPopover).forEach(([key]) => {
        newObject[key] = false;
      });
      setShowPopover({ ...newObject });
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', onClickHandler);
    return () => {
      document.body.removeEventListener('click', onClickHandler);
    };
  }, []);

  return (
    <>
      <Flex
        as="form"
        flexDirection="column"
        height="100%"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          style={{
            flex: '1 1 auto',
            flexGrow: 1,
            overflow: 'auto',
            height: '1px',
          }}
        >
          <Card px={4} py={8}>
            <Box width="max(350px, 50%)">
              <Box
                style={{
                  display: 'grid',
                  gridGap: 3,
                  gridTemplateColumns: '1fr 2fr',
                  alignItems: 'end',
                  position: 'relative',
                }}
              >
                <FormLabel>Phone type</FormLabel>
                <FormLabel>Phone number</FormLabel>
              </Box>
              <Stack
                direction="y"
                gap={2}
                style={{ position: 'relative', zIndex: 3 }}
              >
                {fields?.map((item: any, index: number) => {
                  return (
                    <Box
                      key={`${item.id}`}
                      sx={{
                        display: 'grid',
                        gridGap: 3,
                        gridTemplateColumns: '1fr 2fr',
                        alignItems: 'start',
                        position: 'relative',
                      }}
                    >
                      <FormInput
                        display="none"
                        type="text"
                        register={register}
                        name={`TwilioContactNumbers.${index}.Id`}
                        key={`${item.id}${index}${item?.name}`}
                      />
                      <Controller
                        render={(props: {
                          onChange: (value: number) => void;
                          value: number;
                        }) => {
                          return (
                            <FormSelect
                              value={props.value}
                              options={phoneTypes}
                              onChange={(
                                e: SyntheticEvent<HTMLSelectElement>,
                              ) => {
                                props.onChange(
                                  parseInt(e.currentTarget.value, 10),
                                );
                              }}
                              disabled={!permissions?.isTelephonyAdmin}
                            />
                          );
                        }}
                        defaultValue={''}
                        name={`TwilioContactNumbers.${index}.PhoneTypeId`}
                        control={control}
                      />
                      <Flex style={{ gap: '0.25rem' }}>
                        <div
                          className="pr-ReactSelectWithFlags"
                          style={{
                            position: 'relative',
                            alignSelf: 'flex-start',
                          }}
                        >
                          <Controller
                            render={(props: {
                              onChange: (value: number) => void;
                              value: number;
                            }) => {
                              return (
                                <ReactSelectWithFlags
                                  value={getCountryObj(
                                    props.value,
                                    countryList,
                                  )}
                                  options={optionList(countryList)}
                                  onChange={(e: any) => {
                                    const val = isCountryCodeInUSFormat(
                                      Phone[index]?.CountryId,
                                      e?.value,
                                      countryCode,
                                    );
                                    if (val)
                                      setShowPopover({
                                        ...showPopover,
                                        [`${index}`]: true,
                                      });
                                    else
                                      setShowPopover({
                                        ...showPopover,
                                        [`${index}`]: false,
                                      });
                                    props.onChange(e?.value);
                                  }}
                                  key={`${index}${item?.id}`}
                                  isDisabled={!permissions?.isTelephonyAdmin}
                                />
                              );
                            }}
                            name={`TwilioContactNumbers.${index}.CountryId`}
                            control={control}
                          />
                          {showPopover[`${index}`] === true && (
                            <div ref={popover}>
                              <PhoneNumberChangeAlertWrapper
                                hidden={!showPopover[index]}
                                className={
                                  index === 0
                                    ? 'PhoneNumberChangeAlertWrapper--firstchild'
                                    : ''
                                }
                              >
                                <PhoneNumberChangeAlert>
                                  As you have switched to +1 country code, the
                                  number you have entered initially has been
                                  converted to xxx-xxx-xxxx format.
                                  <Button
                                    size="sm"
                                    className="pr-alert-close"
                                    onClick={() => {
                                      setShowPopover({
                                        ...showPopover,
                                        [`${index}`]: false,
                                      });
                                    }}
                                  >
                                    <Icons.X />
                                  </Button>
                                </PhoneNumberChangeAlert>
                              </PhoneNumberChangeAlertWrapper>
                            </div>
                          )}
                        </div>
                        <div className="pr-masked-input-wrapper">
                          <Controller
                            render={(props: {
                              onChange: (value: number) => void;
                              value: number;
                            }) => {
                              return (
                                <InputMask
                                  id="phn-number"
                                  pattern={maskPattern(Phone[index]?.CountryId)}
                                  value={props.value}
                                  onChange={(e: any) => {
                                    props.onChange(e?.currentTarget?.value);
                                  }}
                                  className="pr-masked-input"
                                  feedback={
                                    errors?.TwilioContactNumbers &&
                                    errors?.TwilioContactNumbers[index]
                                      ?.PhoneNumber?.message
                                  }
                                  disabled={!permissions?.isTelephonyAdmin}
                                />
                              );
                            }}
                            rules={{
                              validate: (value: any) => {
                                if (
                                  isPhnIsInvalidOrNot(
                                    value,
                                    Phone[index]?.CountryId,
                                    countryCode,
                                  ) &&
                                  hasUsFormat(
                                    countryCode,
                                    Phone[index]?.CountryId,
                                  )
                                ) {
                                  return 'Please enter a valid phone number (e.g., xxx-xxx-xxxx)';
                                } else if (
                                  hasUsFormat(
                                    countryCode,
                                    Phone[index]?.CountryId,
                                  ) === false &&
                                  isPhnIsInvalidOrNot(
                                    value,
                                    Phone[index]?.CountryId,
                                    countryCode,
                                  )
                                ) {
                                  return 'Please enter a valid phone number between 8 and 15 digits';
                                }
                              },
                            }}
                            defaultValue={''}
                            name={`TwilioContactNumbers.${index}.PhoneNumber`}
                            control={control}
                          />
                        </div>
                      </Flex>
                      {fields?.length > 1 && permissions?.isTelephonyAdmin ? (
                        <Button
                          variant="ghostDanger"
                          style={{
                            height: '35px',
                            position: 'absolute',
                            top: 0,
                            right: '0',
                            transform: 'translateX(calc(100% + 0.5rem))',
                          }}
                          iconBefore={<Icons.X title="Remove phone number" />}
                          title="Remove"
                          onClick={() => {
                            handleRemove(index);
                          }}
                        />
                      ) : null}
                    </Box>
                  );
                })}
                {permissions?.isTelephonyAdmin && (
                  <Button
                    variant="link"
                    iconBefore={<Icons.Add />}
                    title="Add new number"
                    size="sm"
                    style={{ alignSelf: 'flex-start' }}
                    onClick={() => {
                      handleAppendEntry();
                    }}
                  >
                    Add new number
                  </Button>
                )}
              </Stack>
              <Grid
                minColWidth="350px"
                marginTop={4}
                gap={3}
                style={{ position: 'relative', zIndex: 2 }}
              >
                <Controller
                  render={(props: {
                    onChange: (value: number) => void;
                    value: number;
                  }) => {
                    const value = routingSubGroup?.filter(
                      item => item.value === props.value,
                    );
                    const selected = {
                      label: value && value[0]?.label,
                      value: value && value[0]?.value,
                    };

                    return (
                      <div
                        className="pr-select--question-type"
                        style={{ zIndex: 100 }}
                      >
                        <FormReactSelect
                          value={
                            selected.label === undefined
                              ? { label: 'Select one', value: -1 }
                              : selected
                          }
                          label="Routing Group (Workflow)"
                          options={routingSubGroup}
                          onChange={(e: any) => {
                            props.onChange(parseInt(e.value, 10));
                          }}
                          disabled={!permissions?.isTelephonyAdmin}
                        />
                      </div>
                    );
                  }}
                  defaultValue={''}
                  name="TwilioWorkFlowId"
                  control={control}
                />

                <Controller
                  render={(props: {
                    onChange: (value: number) => void;
                    value: number;
                  }) => {
                    const value = branchOutData?.filter(
                      item => item.id === props.value,
                    );
                    const selected = {
                      label: value && value[0]?.name,
                      value: value && value[0]?.id,
                    };
                    return (
                      <FormReactSelect
                        value={
                          selected.label === undefined && branchOutData
                            ? {
                                label: branchOutData?.find(
                                  item => item?.id === 1,
                                )?.name,
                                value: branchOutData?.find(
                                  item => item?.id === 1,
                                )?.id,
                              }
                            : selected
                        }
                        label="Branch-out Group"
                        options={telephonyDes}
                        onChange={(e: any) => {
                          props.onChange(parseInt(e.value, 10));
                        }}
                        disabled={!permissions?.isTelephonyAdmin}
                      />
                    );
                  }}
                  defaultValue={''}
                  name="TwilioBranchOutConditionsId"
                  control={control}
                />
              </Grid>

              <FormGroup
                pt={1}
                style={{
                  position: 'relative',
                  zIndex: 1,
                }}
                mt={4}
              >
                <Stack direction="x" gap={4} alignItems="center">
                  <FormInput
                    name="TwilioFlexPriority"
                    style={{ flexGrow: 1 }}
                    register={register}
                    label="Twilio Flex Priority"
                    type="number"
                    minValue={0}
                    maxLength={2}
                    maxValue={99}
                    disabled={!permissions?.isTelephonyAdmin}
                    //validation="info"
                    feedback="Please enter a number between 0 and 99."
                  />
                  <Checkbox
                    register={register}
                    label="Call Recording"
                    name="EnableRecording"
                    disabled={!permissions?.isTelephonyAdmin}
                    marginBottom={1}
                  />
                  <Checkbox
                    register={register}
                    label="Press Any Key to Answer"
                    name="TwilioPAK"
                    disabled={!permissions?.isTelephonyAdmin}
                    marginBottom={1}
                  />
                </Stack>
              </FormGroup>
            </Box>
          </Card>
        </Box>

        {permissions?.isTelephonyAdmin && (
          <Flex justifyContent="flex-end" mt={3}>
            <Stack direction="x" gap={2}>
              <Button
                variant="secondary"
                onClick={() => {
                  reset();
                  history.push('/home/accounts');
                }}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                Save
              </Button>
            </Stack>
          </Flex>
        )}
      </Flex>
      {isLoading || isSaveAccountInfoLoading ? <Loader /> : null}
    </>
  );
}

export default Telephony;
